import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";

import { AuthSlice } from "../../Features/Login/AuthSlice";
import TicketSlice from "../../Features/Ticket/TicketSlice";
import TransactionSlice from "../../Features/Transaction/TransactionSlice";
import NotificationSlice from "../../Features/Notification/NotificationSlice";

import { useAuth } from "../../Hooks";

// const state = {};

const extraArgument = { useAuth };

const rootReducer = combineReducers({
  auth: AuthSlice.reducer,
  tickets: TicketSlice.reducer,
  transactions: TransactionSlice.reducer,
  notification: NotificationSlice.reducer,
});

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    thunk: {
      extraArgument,
    },
    serializableCheck: false,
  });

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export { store };
