import React, { useState } from "react";
import { useSelector } from "react-redux";
import ResetNewPasswordForm from "./ResetNewPasswordForm";

const ResetNewPassword = ({
  handlPassword,
  handlConfirm,
  error,
  handlOnSubmit,
}) => {
  return (
    <div id="layoutAuthentication">
      <div id="layoutAuthentication_content">
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <ResetNewPasswordForm
                  handlOnSubmit={handlOnSubmit}
                  handlConfirm={handlConfirm}
                  handlPassword={handlPassword}
                  error={error}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ResetNewPassword;
