import React from "react";
import { Link } from "react-router-dom";

export const ErrorFlashBage = ({ message }) => {
  return (
    <>
      <div class="alert alert-danger my-3 alert-dismissible ">
        <strong>Erreur! </strong> {message}
      </div>
    </>
  );
};

export const Error500 = () => {
  return (
    <div className="sb-nav-fixed">
      <div id="layoutError">
        <div id="layoutError_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="text-center mt-4">
                    <h1 className="display-1">500</h1>
                    <p className="lead">Une erreur inconnue s'est produite</p>
                    <Link to="/">
                      <i className="fas fa-arrow-left me-1"></i>
                      Retourner au tableau de bord
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
