import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getFullServerAddress } from "../../../Utils";

const url = process.env.REACT_APP_SERVER + "/api/auth";

export const signInWithEmailAndPassword = createAsyncThunk(
  "auth/signInWithEmailAndPassword",
  async ({ email, password }, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      //effacer les données stockées dans la memoire interne
      localStorage.clear();

      const response = await axios.post(
        url + "/signin",
        { email, password },
        { withCredentials: true }
      );
      if (response.status !== 200) {
        return rejectWithValue("Erreur de connection");
      }
      const result = await axios.get(url + `/${response.data.userId}`, {
        withCredentials: true,
      });
      const user = { ...result.data.user, loggedIn: true };

      //enregisterer  les données stockées dans la memoire interne
      localStorage.clear();
      localStorage.setItem("user", JSON.stringify(user));

      return fulfillWithValue({ ...user });
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (_, { extra, rejectWithValue }) => {
    console.log("signOut....");
    try {
      // await firebase.auth().signOut();
      localStorage.removeItem("user");
      return {};
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (
    { userId, email, username, password, newPassword },
    { extra, rejectWithValue, fulfillWithValue }
  ) => {
    // console.log("updateProfile....");
    try {
      const res = await axios.put(
        url + `/${userId}`,
        { email, username, password, newPassword },
        { withCredentials: true }
      );
      const newUser = res.data.newUser;
      if (!newUser) {
        return rejectWithValue({ message: "Echec de mise à jour du profile" });
      }
      await localStorage.clear();
      localStorage.setItem("user", JSON.stringify(newUser));
      return fulfillWithValue(newUser);
    } catch (error) {
      const code = error.response.status;
      if (code === 401) {
        localStorage.clear();
      }
      return rejectWithValue({
        message: error.response.data.error,
        code: error.response.status,
      });
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  "auth/sendPasswordResetEmail",
  async ({ email }, { extra, rejectWithValue }) => {
    const host = await getFullServerAddress();
    const callBackUrl = host + "/reset/new-password";

    try {
      await axios.post(url + `/reset-password-request`, {
        email,
        url: callBackUrl,
      });
    } catch (error) {
      console.log(error);
      return rejectWithValue({ message: error.response.data.error });
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ newPassword, token }, { extra, rejectWithValue }) => {
    try {
      await axios.post(url + `/reset-password/${token}`, {
        newPassword,
      });
    } catch (error) {
      console.log(error);
      return rejectWithValue({ message: error.response.data.error });
    }
  }
);
