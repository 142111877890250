function LoadingPage(props) {
  return (
    <div id="layoutAuthentication mt-5">
      <div id="layoutAuthentication_content">
        <main>
          <div className="container mt-5">
            <div className=" d-flex justify-content-center mt-5">
              <div className="spinner-border mt-5" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default LoadingPage;
