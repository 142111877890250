import { Link, Navigate } from "react-router-dom";
import { ErrorFlashBag } from "../../Utils/Style/FlashBags";
import { useSelector } from "react-redux";

const LoginForm = ({
  handlOnSubmit,
  handlEmail,
  handlPassword,
  email,
  password,
}) => {
  const AuthStatus = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);

  return (
    <>
      <div className="card shadow-lg border-0 rounded-lg mt-5">
        <div className="card-header">
          <h3 className="text-center font-weight-light my-4">Connexion</h3>
        </div>
        <div className="card-body">
          {AuthStatus === "failed" && <ErrorFlashBag message={error} />}

          <form onSubmit={handlOnSubmit}>
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="email"
                type="email"
                placeholder="name@example.com"
                onChange={handlEmail}
                value={email}
                required
              />
              <label for="email">Email </label>
            </div>
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="inputPassword"
                type="password"
                placeholder="Password"
                onChange={handlPassword}
                value={password}
                required
              />
              <label for="inputPassword">Password</label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                id="password"
                type="checkbox"
                value=""
              />
              <label className="form-check-label" for="password">
                Remember Password
              </label>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
              <Link className="small" to={"/password/reset"}>
                Mot de passe oublié?
              </Link>
              <button className="btn btn-primary">Login</button>
            </div>
          </form>
        </div>
        <div className="card-footer text-center py-3"></div>
      </div>
    </>
  );
};
export default LoginForm;
