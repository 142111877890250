import AdminPage from "../AdminPage";
import NotificationFeature from "../../Features/Notification/NotificationFeature";
import { Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { LoadingContext } from "../../Hooks";
import { useSelector } from "react-redux";

function NotificationPage(props) {
  const auth = useSelector((state) => state.auth.user);
  const user = localStorage.getItem("user");

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    checkAuth();
  }, [auth]);

  const checkAuth = async () => {
    setIsLoading(!user);
  };
  if (!user) return <Navigate to="/login" />;

  return (
    <>
      <AdminPage>
        <NotificationFeature />
      </AdminPage>
    </>
  );
}

export default NotificationPage;
