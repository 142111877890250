import { createContext, useState } from "react";
import axios from "axios";
import useAuth from "./useAuth";

const useAxios = async (url, methode, body, configs) => {
  switch (methode) {
    case "post":
      try {
        const res = await axios.post(url, body, configs);
        return res;
      } catch (error) {
        console.log(error);
        return error;
      }

    case "get":
      try {
        const res = await axios.get(url, configs);
        return res;
      } catch (error) {
        console.log(error);
        return error;
      }

    case "put":
      try {
        const res = await axios.put(url, body, configs);
        return res;
      } catch (error) {
        console.log(error);
        return error;
      }
    case "delete":
      try {
        const res = await axios.delete(url, configs);
        return res;
      } catch (error) {
        console.log(error);
        return error;
      }

    default:
      break;
  }
};

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handlNavTogle = (e) => {
    e.preventDefault();
    // return console.log(e.target.className);
    document.body.classList.toggle("sb-sidenav-toggled");
    localStorage.setItem(
      "sb|sidebar-toggle",
      document.body.classList.contains("sb-sidenav-toggled")
    );
  };

  return (
    <>
      <LoadingContext.Provider
        value={{ isLoading, setIsLoading, handlNavTogle }}>
        {children}
      </LoadingContext.Provider>
    </>
  );
};

export { LoadingContext, useAxios, useAuth };
