import axios from "axios";
import { sendTelegramToAdmin } from "../../../Services/Api/telegramBot";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const COLLECTION_TICKET = "Tickets";
const PROJET_NAME = process.env.REACT_APP_PROJET_NAME;

const url = process.env.REACT_APP_SERVER + "/api/ticket";

//On televerse un ticket dans la base de donnees
export const ticketAddOne = createAsyncThunk(
  "ticket/addOne",
  async ({ ticket }, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios.post(url + "/", ticket, {
        withCredentials: true,
      });
      const resp = await axios.get(url + "/", {
        withCredentials: true,
      });

      const newTickets = await resp.data.tickets; //recuperer la nouvelle liste des tickets
      return fulfillWithValue(newTickets);
    } catch (error) {
      const code = error.response.status;
      if (code === 401) {
        localStorage.clear();
      }
      return rejectWithValue({
        message: error.message,
        code: error.response.status,
      });
    }
  }
);

//Cette fonction permet d'ajouter plusieur de televerser plusieur tickets
//dans la base de donne en utilisanr une transaction
export const ticketAddMany = createAsyncThunk(
  "ticket/addMany",
  async ({ tickets }, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      // console.log(tickets);
      await axios.post(url + "/many", tickets, {
        withCredentials: true,
      });
      const resp = await axios.get(url + "/", {
        withCredentials: true,
      });

      const newTickets = await resp.data.tickets; //recuperer la nouvelle liste des tickets
      return fulfillWithValue(newTickets);
    } catch (error) {
      console.log(error);
      const code = error.response.status;
      if (code === 401) {
        localStorage.clear();
      }
      return rejectWithValue({
        message: error.response.data.error,
        code: error.response.status,
      });
    }
  }
);

//On recupere tous les tickets
export const ticketSelectAll = createAsyncThunk(
  "ticket/SelectAll",
  async (_, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.get(url, {
        withCredentials: true,
      });
      // console.log(result.data);
      const docs = await result.data.tickets.map((doc) => ({
        id: doc._id,
        ...doc,
      }));
      return fulfillWithValue(docs);
    } catch (error) {
      console.log(error);
      const code = error.response.status;
      if (code === 401) {
        localStorage.clear();
      }
      return rejectWithValue({
        message: error.message,
        code: error.response.status,
      });
    }
  }
);

//On recupere un   ticket grace à un parametre
export const ticketSelectOneBy = createAsyncThunk(
  "ticket/SelectOneBy",
  async ({ condition }, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      const firestore = extra.getFirestore();
      const collectionRef = firestore.collection(COLLECTION_TICKET);
      const querySnapshot = await collectionRef
        .collection(COLLECTION_TICKET)
        .where(condition.parameter, condition.operator, condition.value)
        .limit(condition.limit)
        .get();
      //si au moin un ticket est retrouvé , on retourn 1
      //si non on retour un objet vide
      if (querySnapshot.size >= 1) {
        const doc = querySnapshot.docs[0];
        const id = doc.id;
        const ticket = { ...doc.data(), id };

        return fulfillWithValue(ticket);
      } else {
        return rejectWithValue({ message: "Aucun ticket trouvé" });
      }
    } catch (error) {
      const code = error.response.status;
      if (code === 401) {
        localStorage.clear();
      }
      return rejectWithValue({
        message: error.message,
        code: error.response.status,
      });
    }
  }
);

//Fonction qui gere le payement d'un ticket
export const ticketPayeOne = createAsyncThunk(
  "ticket/PayOne",
  async ({ amount }, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      //on filtre les ticket par prix
      const response = await axios.post(url + "/filter", {
        filter: { price: amount },
      });
      const tickets = response.data.tickets;

      //on gere les difference cas de resultat de la recherche
      switch (true) {
        case tickets.length === 0:
          console.log("querySnapshot.size === 0");
          const text = `Un client a demandé un ticket de ${amount} FCFA qui n'est pas disponible sur le serveur de ${PROJET_NAME} `;
          sendTelegramToAdmin(text);
          const errorMessage = `Aucun ticket de ${amount} FCFA trouvé. Veuillez contacter l'administrateur !!!`;
          return rejectWithValue({ message: errorMessage });

        case tickets.length >= 1:
          if (tickets.length < 10) {
            const text = `Il ne reste que ${tickets.size} ticket(s) de ${amount} FCFA sur le serveur de ${PROJET_NAME} `;
            sendTelegramToAdmin(text);
          }
          // const queryDocumentSnapshot = tickets[0];
          // const data = queryDocumentSnapshot.data();
          // const id = queryDocumentSnapshot.id;
          const ticket = tickets[0];
          console.log(ticket);

          return fulfillWithValue(ticket);

        default:
          break;
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        message: error.response.data.error,
        code: error.response.status,
      });
    }
  }
);

// Fonction qui supprime plusieur tickets par firltre
export const ticketDeleteMany = createAsyncThunk(
  "ticket/DeleteMany",
  async ({ filter }, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      //on supprime les ticket par prix
      await axios.delete(
        url + "/",
        { params: filter },
        {
          withCredentials: true,
        }
      );
      // return ticketSelectAll();
    } catch (error) {
      console.log(error);
      const code = error.response.status;
      if (code === 401) {
        localStorage.clear();
      }
      return rejectWithValue({
        message: error.response.data.error,
        code: error.response.status,
      });
    }
  }
);

// Fonction qui supprime un ticket
export const ticketDeleteOne = createAsyncThunk(
  "ticket/DeleteOne",
  async ({ id }, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      const firestore = extra.getFirestore();
      const collectionRef = firestore.collection(COLLECTION_TICKET);
      const docRef = collectionRef.doc(id);
      await docRef.delete();

      const newTickets = await ticketSelectAll(); //recuperer la nouvelle liste des tickets
      return fulfillWithValue(newTickets);
    } catch (error) {
      const code = error.response.status;
      if (code === 401) {
        localStorage.clear();
      }
      return rejectWithValue({
        message: error.message,
        code: error.response.status,
      });
    }
  }
);
