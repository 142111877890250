import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { LoadingContext } from "../../Hooks";
import AdminPage from "../AdminPage";
import ImporterTickets from "../../Features/Ticket/ImporterTickets";

function PageImporterTicket(props) {
  const auth = useSelector((state) => state.auth.user);
  const user = localStorage.getItem("user");

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    checkAuth();
  }, [auth]);

  const checkAuth = async () => {
    setIsLoading(!user);
  };
  if (!user) return <Navigate to="/login" />;

  return (
    <AdminPage>
      <ImporterTickets />
    </AdminPage>
  );
}

export default PageImporterTicket;
