import React, { useState } from "react";
import LoginForm from "./LoginForm";

const Login = (props) => {
  const { handlOnSubmit, handlEmail, handlPassword, email, password } = props;

  return (
    <>
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <LoginForm
                    handlOnSubmit={handlOnSubmit}
                    handlEmail={handlEmail}
                    handlPassword={handlPassword}
                    email={email}
                    password={password}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Login;
