import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import TicketCard from "../Ticket/TicketCard";
import { ticketDeleteMany, ticketSelectAll } from "../Thunks/TicketThunk";
import LoadingPage from "../../Utils/Style/LoadingPage";
import { ErrorFlashBag } from "../../Utils/Style/FlashBags";
import { Navigate, useNavigate } from "react-router-dom";

const DashboardFeature = () => {
  const tickets = useSelector((state) => state.tickets.list);
  const ticketError = useSelector((state) => state.tickets.error);
  const ticketStatus = useSelector((state) => state.tickets.status);
  const deleteStatus = useSelector((state) => state.tickets.deleteStatus);
  const dispatch = useDispatch();
  const [ticketGroups, setTicketGroups] = useState([]);
  const [pageTitle, setPageTitle] = useState("Dashbord");

  useEffect(() => {
    dispatch(ticketSelectAll());
  }, [deleteStatus]);

  useEffect(() => {
    // on recupere les ticket et on les regroupe par prix dans un tableau
    groupTicketByPrice();
  }, [tickets, ticketError]);

  const groupTicketByPrice = async () => {
    if (tickets) {
      const data = await tickets.reduce((prev, cur) => {
        if (!prev[cur.price]) {
          prev[cur.price] = [cur];
        } else {
          prev[cur.price].push(cur);
        }
        return prev;
      }, []);
      setTicketGroups(data);
    }
  };

  const handldelete = (price) => {
    // alert(`Button clicked for ${price}`);

    const isConfirmed = window.confirm(
      `Êtes-vous sûre de vouloir supprimer tous les tickets de ${price} ?`
    );
    if (isConfirmed) {
      dispatch(ticketDeleteMany({ filter: { price } }));
    }
  };

  return (
    <div className="container-fluid px-4">
      <h1 className="mt-4">{pageTitle}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item active">{pageTitle}</li>
      </ol>
      <div className="row">
        {ticketStatus === "loading" && <LoadingPage />}
        {ticketStatus === "failed" &&
          (ticketError?.code === 401 ? (
            <Navigate to="/login" />
          ) : (
            <ErrorFlashBag message={ticketError.message} />
          ))}
        {ticketStatus === "succeeded" &&
          ticketGroups.length > 0 &&
          ticketGroups.map((groupe, idx) => (
            <TicketCard
              key={idx}
              onDelete={handldelete}
              price={groupe[0].price}
              count={groupe.length}
            />
          ))}
      </div>
    </div>
  );
};

export default DashboardFeature;
