import React from "react";
import Main from "../Main";
import Navbar from "../Navbar";

function Content({ children }) {
  // console.log(Page);
  return (
    <div id="layoutSidenav">
      <Navbar />
      {/* {console.log("=== Content ===")} */}
      <Main>{children}</Main>
    </div>
  );
}
export default Content;
