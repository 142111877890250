export const GlobalFilter=({filter,setFilter})=>{

    return (
        <span>
            <input value={filter || ""} onChange={(e)=>setFilter(e.target.value)} />
        </span>
    );

}

export const ColumnFilter=({column})=>{
    const {filterValue,setFilter}=column;
    return (
        <span>
            <input value={filterValue || ""} onChange={(e)=>setFilter(e.target.value)} />
        </span>
    );

}