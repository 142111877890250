const token = process.env.REACT_APP_TELEGRAM_TOKEN;
const chatId = process.env.REACT_APP_TELEGRAM_CHCAT_ID;

export const sendTelegramToAdmin = (text) => {
  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${text}`;

  return fetch(url)
    .then((res) => {
      console.log("sendTelegramToAdmin response ok");
    })
    .catch((err) => {
      console.log("sendTelegramToAdmin ...catch ...", err);
    });
};
