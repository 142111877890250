import { Link } from "react-router-dom";
import { ErrorFlashBag, SuccessFlashBag } from "../../Utils/Style/FlashBags";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const UploadForm = ({ handlSubmit, handlAmount, handlFile, amount, file }) => {
  const error = useSelector((state) => state.tickets.error);
  const status = useSelector((state) => state.tickets.uploadStatus);
  const success = "Les Tickets ont été sauvegardés avec succès";

  return (
    <>
      <div className="card shadow-lg border-0 rounded-lg mt-5">
        <div className="card-header">
          <h3 className="text-center font-weight-light my-4">
            Importer des Tickets
          </h3>

          <div className=" d-flex my-3 justify-content-center">
            <Link className="btn btn-info btn-sm" to={"/tickets/restants"}>
              Aller à la page des tickets disponibles
            </Link>
          </div>
        </div>
        <div className="card-body mb-2">
          {status === "failed" && <ErrorFlashBag message={error} />}
          {status === "succeeded" && <SuccessFlashBag message={success} />}
          <form onSubmit={handlSubmit}>
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="amount"
                onChange={handlAmount}
                type="number"
                value={amount}
                required
              />
              <label>Prix de vente</label>
            </div>
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="file"
                accept={".csv"}
                onChange={handlFile}
                type={"file"}
                filename={file}
                required
              />
              <label></label>
            </div>

            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
              <button className="btn btn-primary form-control">Importer</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default UploadForm;
