import React, { useState } from "react";
import { ErrorFlashBag, SuccessFlashBag } from "../../Utils/Style/FlashBags";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ResetPasswordForm({ handlOnSubmit, handlEmail, email }) {
  const AuthStatus = useSelector((state) => state.auth.resetPasswordStatus);
  const error = useSelector((state) => state.auth.error);
  const success =
    AuthStatus === "succeeded"
      ? `Un message contenant des instructiona à été envoyé au ${email}. Veuillez consulter ce courier ou le recherher parmis les messages non desirables`
      : null;

  return (
    <div className="card shadow-lg border-0 rounded-lg mt-5">
      <div className="card-header">
        <h3 className="text-center font-weight-light my-4">
          Réinitialisation de mot de passe
        </h3>
        <p className="text-danger text-center">
          Un lien vous sera envoyer à ce email si il est valide
        </p>
      </div>
      <div className="card-body">
        {AuthStatus === "failed" && <ErrorFlashBag message={error.message} />}
        {AuthStatus === "succeeded" && <SuccessFlashBag message={success} />}

        <form onSubmit={handlOnSubmit}>
          <div className="form-floating mb-3">
            <input
              className="form-control"
              id="email"
              type="email"
              placeholder="name@example.com"
              onChange={handlEmail}
              required
            />
            <label for="email">Email </label>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
            <Link className="small" to={"/login"}>
              Se connecter
            </Link>
            <button className="btn btn-primary">Continuer</button>
          </div>
        </form>
      </div>
      <div className="card-footer text-center py-3"></div>
    </div>
  );
}

export default ResetPasswordForm;
