import { createSlice } from "@reduxjs/toolkit";
import {
  ticketSelectAll,
  ticketAddMany,
  ticketAddOne,
  ticketDeleteOne,
  ticketPayeOne,
  ticketSelectOneBy,
  ticketDeleteMany,
} from "../Thunks/TicketThunk";

const TicketSlice = createSlice({
  initialState: {
    list: [],
    ticketInCard: null,
    tickeOne: null,
    error: null,
    status: "idle",
    uploadStatus: "idle",
    deleteStatus: "idle",
  },
  name: "tickets",
  reducers: {},
  extraReducers: (builde) => {
    builde
      .addCase(ticketSelectAll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ticketSelectAll.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(ticketSelectAll.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(ticketAddMany.pending, (state) => {
        state.uploadStatus = "loading";
      })
      .addCase(ticketAddMany.fulfilled, (state, action) => {
        state.uploadStatus = "succeeded";
        state.list = action.payload;
      })
      .addCase(ticketAddMany.rejected, (state, action) => {
        state.uploadStatus = "failed";
        state.error = action.payload;
      })

      .addCase(ticketAddOne.pending, (state) => {
        state.uploadStatus = "loading";
      })
      .addCase(ticketAddOne.fulfilled, (state, action) => {
        state.uploadStatus = "succeeded";
        state.list = action.payload;
      })
      .addCase(ticketAddOne.rejected, (state, action) => {
        state.uploadStatus = "failed";
        state.error = action.payload;
      })

      .addCase(ticketDeleteOne.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ticketDeleteOne.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(ticketDeleteOne.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(ticketDeleteMany.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(ticketDeleteMany.fulfilled, (state, action) => {
        state.deleteStatus = "succeeded";
        state.list = action.payload;
      })
      .addCase(ticketDeleteMany.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.error = action.payload;
      })

      .addCase(ticketPayeOne.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ticketPayeOne.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ticketInCard = action.payload;
      })
      .addCase(ticketPayeOne.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(ticketSelectOneBy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ticketSelectOneBy.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tickeOne = action.payload;
      })
      .addCase(ticketSelectOneBy.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export default TicketSlice;
