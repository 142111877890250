import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signInWithEmailAndPassword } from "../Features/Thunks/AuthThunk";
import LoadingPage from "../Utils/Style/LoadingPage";

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = useSelector((state) => state.auth.user);
  const authStatus = useSelector((state) => state.auth.status);

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const dispatch = useDispatch();

  useEffect(() => {
    checkLogin();
  }, [user]);
  const checkLogin = () => {
    if (!user) {
      setIsAuthenticated(false);
      return setLoading(false);
    }
    setIsAuthenticated(true);
    return setLoading(false);

    // dispatch(signInWithEmailAndPassword(user.email, user.password));
  };

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : isAuthenticated ? (
        <Outlet />
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};
export default PrivateRoute;
