import React from "react";
import { Link } from "react-router-dom";
// import {Avatar,List,ListItem,ListItemAvatar,ListItemText,Divider} from '@material-ui/core'
// import {Book, BookRounded} from '@material-ui/icons'
// import moment from 'moment/min/moment-with-locales'
// import {Link} from 'react-router-dom'

// moment.locale("fr")
const Footer = (props) => {
  const { item } = props;
  return (
    <footer className="py-4 bg-light mt-auto">
      <div className="container-fluid px-4">
        <div className="d-flex align-items-center justify-content-between small">
          <div className="text-muted">
            Copyright &copy; {process.env.REACT_APP_COPY_RIGHT}
          </div>
          <div>
            <b>Design by </b>
            <Link to="mailto:dakustin@gmail.com">dakustin@gmail.com</Link>
            &middot;<span> </span>
            <Link target="_blank" to="https://wa.me/+22892485415">
              {" "}
              whatsapp : +228 92 48 54 15
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
