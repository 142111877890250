import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signOut } from "../../Features/Thunks/AuthThunk";
import { useDispatch, useSelector } from "react-redux";
import { LoadingContext } from "../../Hooks";

const Header = (props) => {
  // on recupere les donnees depuis le store
  const authError = useSelector((state) => state.auth.error);
  const authStatus = useSelector((state) => state.auth.status);
  const auth = useSelector((state) => state.auth.user);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const { handlNavTogle } = useContext(LoadingContext);

  //=======================================

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, [auth]);
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      {/* <!-- Navbar Brand--> */}
      <Link className="navbar-brand ps-3" to="/">
        {process.env.REACT_APP_WIFI_NAME}
      </Link>
      {/* <!-- Sidebar Toggle--> */}
      <button
        className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
        id="sidebarToggle"
        onClick={handlNavTogle}
        href="#!">
        <i className="fas fa-bars"></i>
      </button>
      {/* <!-- Navbar Search--> */}
      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            placeholder="Search for..."
            aria-label="Search for..."
            aria-describedby="btnNavbarSearch"
          />
          <button
            className="btn btn-primary"
            id="btnNavbarSearch"
            type="button">
            <i className="fas fa-search"></i>
          </button>
        </div>
      </form>
      {/* <!-- Navbar--> */}
      {user ? (
        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <i className="fas fa-user fa-fw"></i>
            </Link>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown">
              {user.username && (
                <li>
                  <Link className="dropdown-item">{user.username}</Link>
                </li>
              )}
              <li>
                <hr className="dropdown-divider" />
              </li>
              {user.email && (
                <li>
                  <Link className="dropdown-item">{user.email}</Link>
                </li>
              )}
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/password/reset">
                  Modifier Mot de passe
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/auth/profile">
                  Éditer le profile
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button className="dropdown-item" onClick={handleSignOut}>
                  Deconnexion
                </button>
              </li>
            </ul>
          </li>
        </ul>
      ) : (
        <>
          <Link className="btn btn-primary" to="/login">
            Connexion
          </Link>
        </>
      )}
    </nav>
  );
};
// const mapStateToProps=(state)=>{

//     return {
//         authError:state.auth.authError,
//         auth:state.firebase.auth
//     }
// }
// const mapDispatchToProps=(dispatch)=>{
//     return {
//         logOut:()=>dispatch(logOut())
//     }
// }

// export default connect(mapStateToProps,mapDispatchToProps)(TopNav)

// export default TopNav;

export default Header;
