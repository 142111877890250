import React ,{useMemo,useState }from "react"
import { useTable,useFilters,useSortBy ,useGlobalFilter,usePagination} from "react-table"
import MOCK_DATA from './MOCK_DATA.json'
import { COLUMNS } from "./columns"
import { GlobalFilter,ColumnFilter } from "./tableFilters" 

export const PaginatedTable=props=>{
    const {tickets}=props
    // Create a state
    const [filterInput, setFilterInput] = useState("");

    const columns=useMemo(()=>COLUMNS,[]);
    const data=useMemo(()=>tickets,[]);
    const defaultColumn=useMemo(()=>{return {
        Filter:ColumnFilter
    }},[]);
    const tableInstance=useTable(
        {
            columns,
            data,
            defaultColumn
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow, 
        state,
        setGlobalFilter,
    }=tableInstance;

    const {globalFilter,pageIndex,pageSize}=state



    return (
        <>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
        <table {...getTableProps()} className="table table-bordered table-striped" id="datatablesSimple">
            <thead className="thead thead-dark">
                {headerGroups.map(headerGroup=>(
                <tr {...headerGroup.getHeaderGroupProps()} >
                    {headerGroup.headers.map((column)=>(
                        <th className="align-text-top" {...column.getHeaderProps(column.getSortByToggleProps())} >
                            {column.render('Header')} 
                            {/* {column.render('Filter')} */}

                            <div>{column.canFilter? column.render('Filter'):null}</div>
                            {/* <span> 
                                {
                                    column.isSorted ?
                                    (column.isSortedDesc
                                        ? (<i class="fa fa-caret-up"></i>)
                                        : (<i class="fa fa-caret-down"></i>)
                                    )
                                    : ""
                                }
                            </span> */}
                        </th>
                    ))}
                    
                </tr>
            )
            )}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row)=>{
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps}>
                                {row.cells.map((cell)=>{
                                   return( <td {...cell.getCellProps}>{cell.render('Cell')}</td>)
                                })}
                            
                        </tr>
                        )
                    })
                }
            </tbody>

        </table>
        <div>
            <span className="mr-1">Page {' '} <b>{pageIndex + 1 } sur {pageOptions.length}{' '}</b> </span>
            <span>
                | Aller à la page : {' '}
                <input type="number" defaultValue={pageIndex + 1} 
                onChange={(e)=>{
                    const pageNumber=e.target.value ? Number(e.target.value)-1:0
                    gotoPage(pageNumber)
                }}
                style={{width:'50px'}}
                />
            </span >
            <select className="mx-1" value={pageSize} onChange={(e)=>setPageSize(Number(e.target.value))}>
               { [10,25,50,100].map(pageSize=>
                (<option key={pageSize} value={pageSize}> Afficher {pageSize}</option>)
               )}

            </select>

            <span className="mx-2 align-text-center">
                <button className="btn btn-primary mx-1" onClick={()=>gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                <button className="btn btn-primary mx-1"  onClick={()=>previousPage()} disabled={!canPreviousPage}>Précédent</button>
                <button className="btn btn-primary mx-1"  onClick={()=>nextPage()} disabled={!canNextPage}>Suivant</button>
                <button className="btn btn-primary mx-1"  onClick={()=>gotoPage(pageCount-1)} disabled={!canNextPage}>{'>>'}</button>
            </span>


        </div>
        </>
    )

}