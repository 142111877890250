import React, { useState, useEffect, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { PaginatedTable } from "../../Components/Tables/PaginatedTable";
import LoadingPage from "../../Utils/Style/LoadingPage";
import { useDispatch, useSelector } from "react-redux";
import { LoadingContext } from "../../Hooks";
import { ticketSelectAll } from "../Thunks/TicketThunk";
import { ErrorFlashBag } from "../../Utils/Style/FlashBags";

function TicketsRestants(props) {
  const tickets = useSelector((state) => state.tickets.list);
  const ticketStatus = useSelector((state) => state.tickets.status);
  const error = useSelector((state) => state.tickets.error);
  const dispatch = useDispatch();

  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const pageTitle = "Liste des tickets disponibles";

  useEffect(() => {
    dispatch(ticketSelectAll());
  }, []);

  return (
    <div className="container-fluid px-4">
      <h1 className="mt-4">{pageTitle}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item">
          <Link rel="stylesheet" to="/">
            TABLEAU DE BORD
          </Link>
        </li>
        <li className="breadcrumb-item active">{pageTitle}</li>
      </ol>

      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          {pageTitle}
        </div>
        <div className="card-body overflow-auto">
          {ticketStatus === "loading" && <LoadingPage />}
          {ticketStatus === "failed" &&
            (error?.code === 401 ? (
              <Navigate to="/login" />
            ) : (
              <ErrorFlashBag message={error.message} />
            ))}
          {ticketStatus === "succeeded" && <PaginatedTable tickets={tickets} />}
        </div>
      </div>
    </div>
  );
}

export default TicketsRestants;
