import { Link, Navigate } from "react-router-dom";
import { ErrorFlashBag, SuccessFlashBag } from "../../Utils/Style/FlashBags";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateUserProfile } from "../../Features/Thunks/AuthThunk";
import { useDispatch } from "react-redux";
import axios from "axios";

const ProfileForm = () => {
  const ProfileStatus = useSelector((state) => state.auth.profileStatus);

  const Autherror = useSelector((state) => state.auth.error);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [username, setUsername] = useState(user.username);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  const handlOnSubmit = async (e) => {
    // console.log("handlOnSubmit");

    if (!email) {
      return setError("Email ne peut pas être vide ");
    }
    if (!username) {
      return setError("Username ne peut pas être vide ");
    }
    if ((!password && newPassword) || (password && !newPassword)) {
      return setError(
        "L'ancien mot de passe et le nouveau mot de passe doivent être fournis ensemble ou laissés vides"
      );
    }

    dispatch(
      updateUserProfile({
        email,
        username,
        password,
        newPassword,
        userId: user._id,
      })
    );
  };

  return (
    <>
      <div className="container-fluid px-4">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              <div className="card shadow-lg border-0 rounded-lg mt-5">
                <div className="card-header">
                  <h3 className="text-center font-weight-light my-4">
                    Profile d'utilisateur
                  </h3>
                </div>
                <div className="card-body">
                  {ProfileStatus === "failed" && (
                    <ErrorFlashBag message={Autherror.message} />
                  )}
                  {error && <ErrorFlashBag message={error} />}

                  {ProfileStatus === "succeeded" && (
                    <SuccessFlashBag message="Mise à jour éffectuée avec succès" />
                  )}
                  <form onSubmit={handlOnSubmit}>
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="username"
                        type="text"
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        value={username}
                        required
                      />
                      <label htmlFor="email">Username </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        placeholder="name@example.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        required
                      />
                      <label htmlFor="email">Email </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="oldPassword"
                        type="password"
                        placeholder="Password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        value={password}
                      />
                      <label htmlFor="oldPassword">Password</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="newPassword"
                        type="password"
                        placeholder="New Password"
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        value={newPassword}
                      />
                      <label htmlFor="newPassword">New Password</label>
                    </div>
                  </form>
                </div>
                <div className="card-footer text-center py-3">
                  <button onClick={handlOnSubmit} className="btn btn-primary">
                    Modifier
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileForm;
