import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../Utils/Style/LoadingPage";
import styled from "styled-components";
import { transactionSelectOne } from "../Thunks/TransationThunk";
import { ErrorFlashBag, SuccessFlashBag } from "../../Utils/Style/FlashBags";

const wif_login_url = process.env.REACT_APP_WIFI_LOGIN_URL;

const CenteredDiv = styled.div`
  margin-left: auto !important;
  margin-right: auto !important;
`;

const TransactionSearch = (props) => {
  const [paymentReference, setPaymentReference] = useState();
  const status = useSelector((state) => state.transactions.searchStatus);
  const transaction = useSelector((state) => state.transactions.transaction);
  const error = useSelector((state) => state.transactions.error);
  const dispatch = useDispatch();

  const handlQuery = (e) => {
    setPaymentReference(e.target.value);
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    // console.log("=== paymentReference ===", paymentReference);
    if (paymentReference) {
      dispatch(transactionSelectOne({ paymentReference }));
    }
  };
  const wifiLogin = (username, password) => {
    return (window.location.href = `${wif_login_url}?username=${username}&password=${password}`);
  };
  const copyCode = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <div className="container-fluid px-4">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              <div className="card shadow-lg border-0 rounded-lg mt-5">
                <div className="card-header">
                  <h3 className="text-center font-weight-light my-4">
                    {process.env.REACT_APP_WIFI_NAME}
                  </h3>
                  <p className="text-center">
                    Retrouver votre ticket à l'aide de la référence du payement
                    fourini dans le SMS de confirmation .
                  </p>
                </div>
                <div className="card-body mb-2 ">
                  <CenteredDiv>
                    {status === "succeeded" && transaction.ticket && (
                      <>
                        <SuccessFlashBag
                          message={`Votre ticket est : username = ${
                            JSON.parse(transaction.ticket).username
                          } , password = ${
                            JSON.parse(transaction.ticket).password
                          } . Merci de bien le conserver`}
                        />
                        <div className="d-flex  justify-content-between">
                          <div>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                copyCode(
                                  JSON.parse(transaction.ticket).username
                                )
                              }>
                              Copier username
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                copyCode(
                                  JSON.parse(transaction.ticket).password
                                )
                              }>
                              Copier password
                            </button>
                          </div>
                        </div>
                        <div className="d-flex  justify-content-center mt-2 mb-3">
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              wifiLogin(
                                JSON.parse(transaction.ticket).username,
                                JSON.parse(transaction.ticket).password
                              )
                            }>
                            Se connecter
                          </button>
                        </div>
                      </>
                    )}
                    {status === "failed" && (
                      <ErrorFlashBag message={error.message} />
                    )}
                    {status === "loading" ? (
                      <LoadingPage />
                    ) : (
                      <form onSubmit={handlSubmit}>
                        <div className="form-floating mb-3">
                          <input
                            className="form-control"
                            id="Payment_reference"
                            onChange={handlQuery}
                            value={paymentReference}
                            required
                          />
                          <label>Reference du payement</label>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                          <button className="btn btn-primary form-control">
                            Recherher
                          </button>
                        </div>
                      </form>
                    )}
                  </CenteredDiv>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionSearch;
