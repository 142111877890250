import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../Utils/Style/LoadingPage";
import styled from "styled-components";
import { ErrorFlashBag, SuccessFlashBag } from "../../Utils/Style/FlashBags";
import { notificationSendSMS } from "../Thunks/NotificationThunk";

const CenteredDiv = styled.div`
  margin-left: auto !important;
  margin-right: auto !important;
`;

const NotificationFeature = (props) => {
  const [phone, setPhone] = useState();
  const [message, setmessage] = useState();
  const [sender, setSender] = useState();
  const status = useSelector((state) => state.notification.status);
  const success = useSelector((state) => state.notification.success);
  const error = useSelector((state) => state.transactions.error);
  const dispatch = useDispatch();

  const handlPhone = (e) => {
    setPhone(e.target.value);
  };
  const handlMessage = (e) => {
    setmessage(e.target.value);
  };
  const handlSender = (e) => {
    setSender(e.target.value);
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    if (phone && sender && message) {
      dispatch(notificationSendSMS({ message, from: sender, to: phone }));
    }
  };

  return (
    <>
      <div className="container-fluid px-4">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              <div className="card shadow-lg border-0 rounded-lg mt-5">
                <div className="card-header">
                  <h3 className="text-center font-weight-light my-4">
                    Envoie de SMS
                  </h3>
                </div>
                <div className="card-body mb-2 ">
                  <CenteredDiv>
                    {status === "succeeded" && (
                      <SuccessFlashBag message={success} />
                    )}
                    {status === "failed" && <ErrorFlashBag message={error} />}
                    {status === "loading" ? (
                      <LoadingPage />
                    ) : (
                      <form onSubmit={handlSubmit}>
                        <div className="form-groupe mb-3">
                          <input
                            className="form-control"
                            id="from"
                            onChange={handlSender}
                            placeholder="Espéditeur"
                            value={sender}
                            required
                          />
                        </div>
                        <div className="form-groupe mb-3">
                          <input
                            className="form-control"
                            id="telephone"
                            onChange={handlPhone}
                            type="number"
                            placeholder="Numéro de téléphone"
                            value={phone}
                            required
                          />
                        </div>
                        <div className="form-groupe mb-3">
                          <textarea
                            class="form-control"
                            onChange={handlMessage}
                            placeholder="Votre message"
                            rows="5"
                            id="message"
                            value={message}
                            required
                          />
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                          <button className="btn btn-primary form-control">
                            Envoyer
                          </button>
                        </div>
                      </form>
                    )}
                  </CenteredDiv>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationFeature;
