import React, { useContext, useEffect, useState } from "react";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import UploadForm from "../../Components/Forms/UploadForm";
import LoadingPage from "../../Utils/Style/LoadingPage";
import { ticketAddMany } from "../Thunks/TicketThunk";
import { Navigate } from "react-router-dom";
import { LoadingContext } from "../../Hooks";

const ImporterTickets = (props) => {
  const [amount, setAmount] = useState();
  const [file, setFile] = useState();
  const status = useSelector((state) => state.tickets.uploadStatus);
  const { setIsLoading, isLoading } = useContext(LoadingContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (status === "succeeded") {
      setAmount("");
      setFile("");
    }
  }, [status]);

  const handlAmount = (e) => {
    setAmount(e.target.value);
  };

  const handlFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    try {
      if (file && amount) {
        console.log("Papa.parse begining");
        //on converti le fichier csv en object
        Papa.parse(file, {
          complete: async (result) => {
            const data = result.data;
            const tickets = await data.reduce((acc, cur) => {
              const ticket = { ...cur, Price: amount };
              acc.push(ticket); // ici on ajoute le montant à chaque ticket
              return acc;
            }, []);
            // console.log("Papa.parse complete ===", tickets);
            dispatch(ticketAddMany({ tickets }));
          },
          header: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container-fluid px-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              {status === "loading" ? (
                <LoadingPage />
              ) : (
                <UploadForm
                  handlAmount={handlAmount}
                  handlFile={handlFile}
                  handlSubmit={handlSubmit}
                  amount={amount}
                  file={file}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImporterTickets;
