import { createSlice } from "@reduxjs/toolkit";
import { notificationSendSMS } from "../Thunks/NotificationThunk";

const NotificationSlice = createSlice({
  name: "notification",
  initialState: {
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {},
  extraReducers: (builde) => {
    builde
      .addCase(notificationSendSMS.pending, (state) => {
        state.status = "loading";
      })
      .addCase(notificationSendSMS.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload;
      })
      .addCase(notificationSendSMS.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default NotificationSlice;
