import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { LoadingContext } from "../../Hooks";
import AdminPage from "../AdminPage";
import { Transaction } from "../../Features/Transaction/";

function TransactionPage(props) {
  const auth = useSelector((state) => state.auth);
  const user = localStorage.getItem("user");
  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    checkAuth();
  }, [auth]);

  const checkAuth = async () => {
    setIsLoading(!auth);
  };
  if (!user) return <Navigate to="/login" />;

  return (
    <AdminPage>
      <Transaction />
    </AdminPage>
  );
}

export default TransactionPage;
