import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navitem from "./Navitem";

const Navbar = (props) => {
  const auth = useSelector((state) => state.auth.user);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, [auth]);

  // console.log(user);
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className="nav">
            <div className="sb-sidenav-menu-heading">####</div>

            <Link className="nav-link" to="/">
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              Tableau de bord
            </Link>

            <div className="sb-sidenav-menu-heading">Interfaces</div>

            {/* Tickets */}

            <a
              className="nav-link collapsed"
              href="#"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTickets"
              aria-expanded="false"
              aria-controls="collapseTickets">
              <div className="sb-nav-link-icon">
                <i className="fas fa-columns"></i>
              </div>
              Tickets
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down"></i>
              </div>
            </a>
            <div
              className="collapse"
              id="collapseTickets"
              aria-labelledby="headingOne"
              data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link" to="/tickets/importer">
                  Importer
                </Link>
                <Link className="nav-link" to="/tickets/restants">
                  Restants
                </Link>
              </nav>
            </div>

            {/* Notifications */}
            <a
              className="nav-link collapsed"
              href="#"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTransactions"
              aria-expanded="false"
              aria-controls="collapseTransactions">
              <div className="sb-nav-link-icon">
                <i className="fas fa-columns"></i>
              </div>
              Transactions
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down"></i>
              </div>
            </a>
            <div
              className="collapse"
              id="collapseTransactions"
              aria-labelledby="headingOne"
              data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link" to="/transactions/list">
                  Liste des transactions
                </Link>
                <Link className="nav-link" to="/transaction/ref/ticket">
                  Rechercher
                </Link>
              </nav>
            </div>

            <a
              className="nav-link collapsed"
              href="#"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNotification"
              aria-expanded="false"
              aria-controls="collapseNotification">
              <div className="sb-nav-link-icon">
                <i className="fas fa-columns"></i>
              </div>
              Notification
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down"></i>
              </div>
            </a>
            <div
              className="collapse"
              id="collapseNotification"
              aria-labelledby="headingOne"
              data-bs-parent="#sidenavAccordion">
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link" to="/notification/sms">
                  Envoyer SMS
                </Link>
              </nav>
            </div>
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="small">Logged in as:</div>
          {user?.email}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
