export const API_TOKEN = process.env.REACT_APP_WASSASMS_API_TOKEN;
export const BASE_URL = process.env.REACT_APP_WASSASMS_BASE_URL;

export const sendWassaSMS = async (from, to, text) => {
  const msg = `${text}.`;
  const response = await fetch(
    `${BASE_URL}?access-token=${API_TOKEN}&sender=${from}&receiver=${to}&text=${msg}`
  );
  const data = await response.json();
  return data;
};

export const smsWithReturn = (from, to, text, complet) => {
  const msg = `${text}.`;
  fetch(
    `${BASE_URL}?access-token=${API_TOKEN}&sender=${from}&receiver=${to}&text=${msg}`
  )
    .then((data) => {
      console.log("sendWassaSMS success data");
      return complet(data);
    })
    .catch((err) => {
      console.error(err);
      return complet(err);
    });
};
