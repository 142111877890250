import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../Utils/Style/LoadingPage";
import { ticketDeleteMany } from "../Thunks/TicketThunk";
import { Navigate } from "react-router-dom";
import { LoadingContext } from "../../Hooks";

function TicketCard({ price, count, onDelete }) {
  const handleClick = (e) => {
    e.preventDefault();
    onDelete(price);
  };
  return (
    <>
      <div className="col-xl-3 col-md-6">
        <div className="card bg-info text-white mb-4">
          <div className="card-body">{price ? price : 0} FCFA</div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <span className="small text-white ">Disponible</span>
            <div className="small text-white">
              {count ? (
                count
              ) : (
                <span className="spinner-grow spinner-grow-sm "></span>
              )}
            </div>
            <button onClick={handleClick} className="btn btn-danger">
              <i className="fas fa-trash fa-fw"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TicketCard;
