import React, { useContext } from "react";
import Header from "../Components/Header";
import Content from "../Components/Content";
import LoadingPage from "../Utils/Style/LoadingPage";
import { LoadingContext } from "../Hooks";

const AdminPage = ({ children }) => {
  const { isLoading } = useContext(LoadingContext);

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="sb-nav-fixed">
          <Header />
          <Content>{children}</Content>
        </div>
      )}
    </>
  );
};

export default AdminPage;
