import moment from "moment/min/moment-with-locales";
import { ColumnFilter } from "./tableFilters";

moment.locale("fr");

export const COLUMNS = [
  {
    Header: "ID",
    accessor: "id",
    disableFilters: true,
    Cell: ({ cell: { value, row } }) => row.index + 1,
  },
  {
    Header: "Username",
    accessor: "username",
    // disableFilters: true,
  },
  {
    Header: "Profile",
    accessor: "profile",
    // disableFilters: true,
  },
  {
    Header: "Price",
    accessor: "price",
    disableFilters: true,
  },
  {
    Header: "Comment",
    accessor: "comment",
    disableFilters: true,
  },
  {
    Header: "Created At",
    accessor: "created_at",
    Cell: ({ cell: { value } }) => moment(value).format("ll"),
  },
];

export const TRANSACTION_COLUMNS = [
  {
    Header: "#",
    accessor: "idx",
    disableFilters: true,
    Cell: ({ cell: { value, row } }) => row.index + 1,
  },
  {
    Header: "Identifiant",
    accessor: "id",
  },
  {
    Header: "Montant",
    accessor: "amount",
    Cell: ({ cell: { value } }) => (value ? `${value} FCFA` : ""),
  },

  {
    Header: "Ticket",
    accessor: "ticket",

    disableFilters: true,
  },

  {
    Header: "Etat",
    accessor: "tx_status",
    disableFilters: true,
  },
  {
    Header: "Méthode",
    accessor: "payment_method",
    disableFilters: true,
  },

  {
    Header: "Téléphone",
    accessor: "phone_number",
  },

  {
    Header: "Tx_reference",
    accessor: "tx_reference",
    disableFilters: true,
  },

  {
    Header: "Payment_reference",
    accessor: "payment_reference",
  },

  {
    Header: "Initié le",
    accessor: "created_at",
    Cell: ({ cell: { value } }) =>
      value ? moment(value).format("llll") : "null",
  },

  {
    Header: "Payé le",
    accessor: "datetime",
    Cell: ({ cell: { value } }) =>
      value ? moment(value).format("llll") : "null",
  },
];
