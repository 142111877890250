import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendWassaSMS } from "../../../Services/Api/wassasms";

export const notificationSendSMS = createAsyncThunk(
  "notification/sendSMS",
  async (
    { from, to, message },
    { extra, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const resp = await sendWassaSMS(from, to, message);
      // console.log(" === notificationSendSMS ===", resp);
      switch (resp.status) {
        case "success":
          return fulfillWithValue("Votre méssage a été envoyé avec succès");
        case "failed":
          return rejectWithValue(
            "Une erreur incconue sést produite au cours de l'envois de votre méssage"
          );

        default:
          break;
      }
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);
