import { createSlice } from "@reduxjs/toolkit";
import {
  transactionInit,
  transactionFindTicket,
  transactionSelectAll,
  transactionSelectOne,
} from "../Thunks/TransationThunk";

export const TransactionSlice = createSlice({
  initialState: {
    list: [],
    init: null,
    transaction: null,
    error: null,
    status: "idle",
    searchStatus: "idle",
    ticket: null,
  },
  name: "transactions",
  reducers: {},
  extraReducers: (builde) => {
    builde
      .addCase(transactionSelectAll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(transactionSelectAll.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(transactionSelectAll.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(transactionInit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(transactionInit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.init = action.payload;
      })
      .addCase(transactionInit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(transactionSelectOne.pending, (state) => {
        state.searchStatus = "loading";
      })
      .addCase(transactionSelectOne.fulfilled, (state, action) => {
        state.searchStatus = "succeeded";
        state.transaction = action.payload;
      })
      .addCase(transactionSelectOne.rejected, (state, action) => {
        state.searchStatus = "failed";
        state.error = action.payload;
      })

      .addCase(transactionFindTicket.pending, (state) => {
        state.searchStatus = "loading";
      })
      .addCase(transactionFindTicket.fulfilled, (state, action) => {
        state.searchStatus = "succeeded";
        state.ticket = action.payload;
      })
      .addCase(transactionFindTicket.rejected, (state, action) => {
        state.searchStatus = "failed";
        state.error = action.payload;
      });
  },
});
export default TransactionSlice;
