export const ErrorFlashBag = ({ message }) => {
  return (
    <>
      <div className="alert alert-danger my-3 alert-dismissible ">
        <strong>Erreur! </strong> {message}
      </div>
    </>
  );
};

export const SuccessFlashBag = ({ message }) => {
  return (
    <>
      <div className="alert alert-success my-3 alert-dismissible ">
        <strong>Succès! </strong> {message}
      </div>
    </>
  );
};
