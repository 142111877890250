import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { LoadingContext } from "../../Hooks";
import AdminPage from "../AdminPage";
import { ProfileFeature as Profile } from "../../Features/Login";

function ProfilePage(props) {
  const authState = useSelector((state) => state.auth.status);
  const auth = useSelector((state) => state.auth.user);
  const user = JSON.parse(localStorage.getItem("user"));

  const { isLoading, setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    checkAuth();
  }, [auth]);

  const checkAuth = async () => {
    await setIsLoading(authState === "loading");
    // console.log(user);
  };
  if (!user) return <Navigate to="/login" />;

  // if (!auth.loggedIn) return <Navigate to="/login" />;
  // if (authState === "succeeded") {

  // }

  return (
    <AdminPage>
      <Profile />
    </AdminPage>
  );
}

export default ProfilePage;
