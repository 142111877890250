import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardPage from "../Pages/Dashboard/";
import Error500 from "../Pages/Errors/";
import {
  Login,
  Resetpassword,
  ResetNewPassword,
  ProfilePage,
} from "../Pages/Login";
import { PageTicketRestant, PageImporterTicket } from "../Pages/Tickets";
import { TransactionPage, TransactionSearchPage } from "../Pages/Transaction";
import { BookTicketRequest, BookTicketResult } from "../Pages/Transaction/";
import { NotificationPage } from "../Pages/Notification";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route path="" element={<DashboardPage />} />
        </Route>
        <Route path="/login" element={<Login />} />

        <Route exact path="/auth/profile" element={<PrivateRoute />}>
          <Route path="" element={<ProfilePage />} />
        </Route>
        {/* <Route path="/auth/profile" element={<ProfilePage />} /> */}

        <Route exact path="/tickets/importer" element={<PrivateRoute />}>
          <Route path="" element={<PageImporterTicket />} />
        </Route>
        {/* <Route path="/tickets/importer" element={<PageImporterTicket />} /> */}

        <Route exact path="/transactions/list" element={<PrivateRoute />}>
          <Route path="" element={<TransactionPage />} />
        </Route>
        {/* <Route path="/transactions/list" element={<TransactionPage />} /> */}

        <Route exact path="/tickets/restants" element={<PrivateRoute />}>
          <Route path="" element={<PageTicketRestant />} />
        </Route>
        {/* <Route path="/tickets/restants" element={<PageTicketRestant />} /> */}

        <Route exact path="/notification/sms" element={<PrivateRoute />}>
          <Route path="" element={<NotificationPage />} />
        </Route>
        {/* <Route path="/notification/sms" element={<NotificationPage />} /> */}
        <Route
          path="/transaction/ref/ticket"
          element={<TransactionSearchPage />}
        />
        <Route path="/tickets/payed/find" element={<BookTicketResult />} />
        <Route path="/tickets/request" element={<BookTicketRequest />} />
        <Route path="/password/reset" element={<Resetpassword />} />
        <Route
          path="/reset/new-password/:token"
          element={<ResetNewPassword />}
        />

        <Route path="*" element={<Error500 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
