import { useState, useEffect } from "react";
import axios from "axios";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState(null);

  const port = process.env.PORT || 3000;

  const url = "http://localhost:" + port + "/api/auth";

  const fetchAll = async () => {
    try {
      setLoading(true);
      const response = await axios.get(url, { withCredentials: true });
      setUsers(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(url + `/${id}`, {
        withCredentials: true,
      });
      setUser(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const resetRequest = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(url + `/reset-request`, {
        withCredentials: true,
      });
      setUser(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const register = async (newUser) => {
    try {
      setLoading(true);
      const response = await axios.post(
        url + `/register`,
        { ...newUser },
        { withCredentials: true }
      );
      setUser(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const login = async ({ username, password }) => {
    try {
      setLoading(true);
      const response = await axios.post(url + `/login`, { username, password });

      console.log(response);
      setLoggedIn(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async (id, data) => {
    try {
      setLoading(true);
      const response = await axios.put(
        url + `/${id}`,
        { ...data },
        { withCredentials: true }
      );
      setUser(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (token, newPassword) => {
    try {
      setLoading(true);
      const response = await axios.put(url + `/reset/${token}`, {
        newPassword,
      });
      setUser(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = () => {};

  useEffect(() => {
    fetchAll();
  }, []);

  return {
    user,
    users,
    error,
    loading,
    loggedIn,
    getUser,
    resetRequest,
    register,
    resetPassword,
    updateProfile,
    deleteUser,
    login,
  };
};

export default useAuth;
