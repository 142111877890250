import React, { useContext } from "react";
import Footer from "../Footer";
import { LoadingContext } from "../../Hooks";

// moment.locale("fr")
const Main = ({ children }) => {
  const { handlNavTogle } = useContext(LoadingContext);

  return (
    <div id="layoutSidenav_content">
      <main>{children}</main>
      <Footer />
    </div>
  );
};
export default Main;
