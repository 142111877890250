import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import LoadingPage from "../../Utils/Style/LoadingPage";
import Login, { ProfileForm } from "../../Components/Login/";
import ResetPassword from "../../Components/Login/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  resetPassword,
} from "../Thunks/AuthThunk";
import ResetNewPassword from "../../Components/Login/ResetNewPassword";

const ResetPasswordFeature = () => {
  //ici on recupere les donnees depuis le store
  const authStatus = useSelector((state) => state.auth.resetPasswordStatus);
  //==========================================================

  const dispatch = useDispatch();

  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(() => authStatus === "loading");
  }, [authStatus]);

  const handlEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlOnSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(sendPasswordResetEmail({ email }));
  };

  return (
    <>
      {authStatus === "loading" && <LoadingPage />}
      {authStatus !== "loading" && (
        <ResetPassword
          handlOnSubmit={handlOnSubmit}
          handlEmail={handlEmail}
          email={email}
        />
      )}
    </>
  );
};

const ResetNewPasswordFeature = () => {
  //ici on recupere les donnees depuis le store
  const authStatus = useSelector((state) => state.auth.resetPasswordStatus);
  //==========================================================

  const dispatch = useDispatch();
  const { token } = useParams();

  const [password, setPassword] = useState();
  const [confirm, setConfirm] = useState();
  const [error, setError] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(() => authStatus === "loading");
  }, [authStatus]);

  useEffect(() => {
    setError("");
  }, []);

  const handlPassword = (e) => {
    setPassword(e.target.value);
  };
  const handlConfirm = (e) => {
    setConfirm(e.target.value);
  };

  const handlOnSubmit = (e) => {
    e.preventDefault();

    if (password.length === 0 || confirm.length === 0) {
      return setError("Le mot de passe ne peut être vide.");
    }
    if (password !== confirm) {
      return setError("Les mots de passe doivent être identiques.");
    }
    dispatch(resetPassword({ newPassword: password, token }));
  };

  return (
    <>
      {authStatus === "loading" && <LoadingPage />}
      {authStatus !== "loading" && (
        <ResetNewPassword
          handlOnSubmit={handlOnSubmit}
          handlPassword={handlPassword}
          handlConfirm={handlConfirm}
          error={error}
        />
      )}
    </>
  );
};
const LoginFeature = () => {
  //ici on recupere les donnees depuis le store
  const auth = useSelector((state) => state.auth.user);

  const authStatus = useSelector((state) => state.auth.status);
  //==========================================================

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const location = useLocation();
  const referer = location.state?.referer;
  console.log("referer", referer);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    if (user) {
      referer ? navigate(referer) : navigate("/");
    }
  }, [user, auth, navigate]);

  // if (user) return <Navigate to="/" />;

  const handlEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlPassword = (e) => {
    setPassword(e.target.value);
  };

  const handlOnSubmit = (e) => {
    e.preventDefault();
    // console.log("=== handlOnSubmit ===", email, password);
    dispatch(signInWithEmailAndPassword({ email, password }));
  };

  return (
    <>
      {authStatus === "loading" ? (
        <LoadingPage />
      ) : (
        <Login
          handlOnSubmit={handlOnSubmit}
          handlEmail={handlEmail}
          handlPassword={handlPassword}
          email={email}
          password={password}
        />
      )}
    </>
  );
};

const ProfileFeature = () => {
  //ici on recupere les donnees depuis le store
  const auth = useSelector((state) => state.auth.user);

  const authStatus = useSelector((state) => state.auth.profileStatus);
  //==========================================================

  return <>{authStatus === "loading" ? <LoadingPage /> : <ProfileForm />}</>;
};

export {
  ResetPasswordFeature,
  ResetNewPasswordFeature,
  LoginFeature,
  ProfileFeature,
};
