import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import App from "./App/";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { LoadingProvider } from "./Hooks";
import { store } from "./App/Store";

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <LoadingProvider>
//         <App />
//       </LoadingProvider>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <LoadingProvider>
        <App />
      </LoadingProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
