import { createSlice } from "@reduxjs/toolkit";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateUserProfile,
  resetPassword,
} from "../Thunks/AuthThunk";

export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      id: null,
      username: null,
      email: null,
      loggedIn: false,
    },
    status: "idle",
    error: null,
    resetPasswordStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signInWithEmailAndPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signInWithEmailAndPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(signInWithEmailAndPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(signOut.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signOut.fulfilled, (state) => {
        state.status = "succeeded";
        state.user = null;
        console.log("signOut.fulfilled");
      })
      .addCase(signOut.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(updateUserProfile.pending, (state) => {
        state.profileStatus = "loading";
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.profileStatus = "succeeded";
        state.error = null;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.profileStatus = "failed";
        state.error = action.payload;
      })

      .addCase(sendPasswordResetEmail.pending, (state) => {
        state.resetPasswordStatus = "loading";
      })
      .addCase(sendPasswordResetEmail.fulfilled, (state) => {
        state.resetPasswordStatus = "succeeded";
        state.error = null;
      })
      .addCase(sendPasswordResetEmail.rejected, (state, action) => {
        state.resetPasswordStatus = "failed";
        state.error = action.payload;
      })

      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordStatus = "loading";
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordStatus = "succeeded";
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordStatus = "failed";
        state.error = action.payload;
      });
  },
});
