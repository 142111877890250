import React, { useEffect, useState } from "react";
import { ErrorFlashBag, SuccessFlashBag } from "../../Utils/Style/FlashBags";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ResetNewPasswordForm = ({
  handlPassword,
  handlConfirm,
  error,
  handlOnSubmit,
}) => {
  const AuthStatus = useSelector((state) => state.auth.resetPasswordStatus);
  const authError = useSelector((state) => state.auth.error);
  const success =
    AuthStatus === "succeeded"
      ? `Votre mot de passe a été réinitialisé avec succès`
      : null;

  return (
    <div className="card shadow-lg border-0 rounded-lg mt-5">
      <div className="card-header">
        <h3 className="text-center font-weight-light my-4">
          Réinitialisation de mot de passe
        </h3>
      </div>
      <div className="card-body">
        {AuthStatus === "failed" && (
          <ErrorFlashBag message={authError.message} />
        )}
        {error && <ErrorFlashBag message={error} />}
        {AuthStatus === "succeeded" && <SuccessFlashBag message={success} />}

        <form onSubmit={handlOnSubmit}>
          <div className="form-floating mb-3">
            <input
              className="form-control"
              id="password"
              type="password"
              onChange={handlPassword}
              required
            />
            <label htmlFor="password">Mot de passe </label>
          </div>
          <div className="form-floating mb-3">
            <input
              className="form-control"
              id="confirmPassword"
              type="password"
              onChange={handlConfirm}
              required
            />
            <label htmlFor="confirmPassword">confirmer mot de passe</label>
          </div>

          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
            <Link className="small" to={"/login"}>
              Se connecter
            </Link>
            <button className="btn btn-primary">Continuer</button>
          </div>
        </form>
      </div>
      <div className="card-footer text-center py-3"></div>
    </div>
  );
};

export default ResetNewPasswordForm;
