import React, { useState } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { useSelector } from "react-redux";

const ResetPassword = ({ handlOnSubmit, handlEmail, email }) => {
  return (
    <div id="layoutAuthentication">
      <div id="layoutAuthentication_content">
        <main>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <ResetPasswordForm
                  handlOnSubmit={handlOnSubmit}
                  handlEmail={handlEmail}
                  email={email}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ResetPassword;
