import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { LoadingContext } from "../../Hooks";
import AdminPage from "../AdminPage";
import { TransactionSearch } from "../../Features/Transaction/";

function TransactionSearchPage(props) {
  const auth = useSelector((state) => state.auth);
  const { setIsLoading } = useContext(LoadingContext);
  const user = localStorage.getItem("user");

  return (
    <>
      {user ? (
        <AdminPage>
          <TransactionSearch />
        </AdminPage>
      ) : (
        <TransactionSearch />
      )}
    </>
  );
}

export default TransactionSearchPage;
