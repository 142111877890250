import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BASE_URL1, PAYGATE_TOKEN } from "../../Services/Api/paygate";
import LoadingPage from "../../Utils/Style/LoadingPage";
import { ticketPayeOne } from "../../Features/Thunks/TicketThunk";
import { transactionInit } from "../../Features/Thunks/TransationThunk";
import { useDispatch, useSelector } from "react-redux";
import { ErrorFlashBag } from "../../Utils/Style/FlashBags";

function BookTicketRequest(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const amount = queryParams.get("price");
  const url = queryParams.get("url");

  const [qUrl, setQurl] = useState(url);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const ticketCard = useSelector((state) => state.tickets.ticketInCard);
  const transaction = useSelector((state) => state.transactions.init);
  const ticketStatus = useSelector((state) => state.tickets.status);
  const ticketError = useSelector((state) => state.tickets.error);
  const transactionStatus = useSelector((state) => state.transactions.status);
  const transactionError = useSelector((state) => state.transactions.error);

  useEffect(() => {
    requestTicket();
  }, []);

  const requestTicket = async () => {
    // setIsLoading(true);
    if (amount) {
      console.log("== requestTicket-amoun  ==", amount);
      await dispatch(ticketPayeOne({ amount }));
      // setIsLoading(() => ticketStatus === "succeeded");
    }
  };
  useEffect(() => {
    const d = async () => {
      // setIsLoading(true);
      if (ticketCard) {
        console.log("== requestTicket-ticketCard ==", ticketCard);
        await dispatch(transactionInit());
      }
    };
    d();
  }, [ticketStatus]);

  useEffect(() => {
    if (transaction) {
      procedeToPayment();
    }
  }, [transaction]);
  useEffect(() => {
    if (ticketStatus === "failed" || transactionStatus === "failed") {
      setIsLoading(false);
    }
  });

  const procedeToPayment = async () => {
    try {
      if (ticketStatus === "failed" || transactionStatus === "failed") {
        setIsLoading(false);
      }
      // setIsLoading(false);
      // return window.location.replace("https://openclassrooms.com");

      const baseUrl = await getBaseURL();
      // console.log("baseUrl url == ", baseUrl);
      const pathToFindPayedTicket = `/tickets/payed/find?identifier=${transaction._id}`;

      // await setQurl(baseUrl + pathToFindPayedTicket);

      const refUrl = await (baseUrl + pathToFindPayedTicket);
      // console.log("fallback url == ", refUrl);
      // return console.log("=== procedeToPayment ===", {
      //   id: transaction.id,
      //   refUrl,
      // });
      // return window.location.replace("https://openclassrooms.com");

      return window.location.replace(
        `${BASE_URL1}?token=${PAYGATE_TOKEN}&amount=${amount}&identifier=${transaction._id}&url=${refUrl}`
      );
      // return (window.location.href = `${BASE_URL1}?token=${PAYGATE_TOKEN}&amount=${amount}&identifier=${transaction.id}&url=${refUrl}`);
    } catch (err) {
      setError(true);
    }
  };

  const getBaseURL = () => {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}${port ? `:${port}` : ""}`;
  };

  return (
    <>
      {/* {isLoading ? (
        <LoadingPage />
      ) : ( */}
      {/* {transaction} */}
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  {isLoading ? (
                    <LoadingPage />
                  ) : (
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                      <div className="card-header">
                        <h3 className="text-center font-weight-light my-4">
                          {process.env.REACT_APP_WIFI_NAME}
                        </h3>
                      </div>
                      <div className="card-body ">
                        {/* {isLoading && <LoadingPage />} */}

                        {ticketStatus === "failed" && (
                          <ErrorFlashBag message={ticketError.message} />
                        )}
                        {transactionStatus === "failed" && (
                          <ErrorFlashBag message={transactionError.message} />
                        )}
                        {error && (
                          <ErrorFlashBag message="Une erreur inconnue s'est produite" />
                        )}
                      </div>
                      <div className="card-footer text-center py-3"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default BookTicketRequest;
