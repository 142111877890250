import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../../Utils/Style/LoadingPage";
import styled from "styled-components";
import { transactionFindTicket } from "../../Features/Thunks/TransationThunk";
import { ErrorFlashBag, SuccessFlashBag } from "../../Utils/Style/FlashBags";
import { Link, useLocation } from "react-router-dom";

const CenteredDiv = styled.div`
  margin-left: auto !important;
  margin-right: auto !important;
`;
const wif_login_url = process.env.REACT_APP_WIFI_LOGIN_URL;

const BookTicketResult = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const identifier = queryParams.get("identifier");

  const status = useSelector((state) => state.transactions.searchStatus);
  const ticket = useSelector((state) => state.transactions.ticket);
  const error = useSelector((state) => state.transactions.error);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(transactionFindTicket({ identifier }));
  }, []);
  useEffect(() => {
    if (status === "loading") {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [status]);

  const wifiLogin = () => {
    const { username, password } = ticket;

    return (window.location.href = `${wif_login_url}?username=${username}&password=${password}`);
  };
  const copyCode = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <div className="container-fluid px-4">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5">
              {isLoading ? (
                <LoadingPage />
              ) : (
                <div className="card shadow-lg border-0 rounded-lg mt-5">
                  <div className="card-header">
                    <h3 className="text-center font-weight-light my-4">
                      {process.env.REACT_APP_WIFI_NAME}
                    </h3>
                  </div>
                  <div className="card-body mb-2 ">
                    <CenteredDiv>
                      {status === "succeeded" && ticket && (
                        <>
                          <SuccessFlashBag
                            message={`Votre ticket est : username = ${ticket.username} , password = ${ticket.password} . Merci de bien le conserver`}
                          />
                          <div className="d-flex justify-content-between">
                            <div>
                              <button
                                className="btn btn-primary"
                                onClick={() => copyCode(ticket.username)}>
                                Copier username
                              </button>
                            </div>
                            <div>
                              <button
                                className="btn btn-primary"
                                onClick={() => copyCode(ticket.password)}>
                                Copier password
                              </button>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mt-2">
                            <button
                              className="btn btn-success"
                              onClick={wifiLogin}>
                              Se connecter
                            </button>
                          </div>
                        </>
                      )}
                      {status === "failed" && (
                        <ErrorFlashBag message={error.message} />
                      )}
                    </CenteredDiv>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookTicketResult;
