import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_SERVER + "/api/transaction";

export const transactionInit = createAsyncThunk(
  "transation/init",
  async (_, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios.post(url);

      const transaction = response.data.transaction;

      return fulfillWithValue(transaction);
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        message: error.response.data.error,
        code: error.response.status,
      });
    }
  }
);

export const transactionSelectAll = createAsyncThunk(
  "transation/selectAll",
  async (_, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.get(url, {
        withCredentials: true,
      });
      // console.log(result.data.tickets);
      const docs = await result.data.transactions.map((doc) => ({
        id: doc._id,
        ...doc,
      }));

      return fulfillWithValue(docs);
    } catch (error) {
      const code = error.response.status;
      if (code === 401) {
        localStorage.clear();
      }
      return rejectWithValue({
        message: error.message,
        code: error.response.status,
      });
    }
  }
);

export const transactionFindTicket = createAsyncThunk(
  "transation/findTicket",
  async ({ identifier }, { extra, rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios.get(url + `/${identifier}`);

      const transaction = response.data.transaction;

      if (transaction?.ticket) {
        // console.log("=== transaction  ===", transaction);

        return fulfillWithValue(JSON.parse(transaction.ticket));
      } else {
        return rejectWithValue({ message: "Ticket non disponoble" });
      }
    } catch (error) {
      return rejectWithValue({
        message: error.response.data.error,
        code: error.response.status,
      });
    }
  }
);

export const transactionSelectOne = createAsyncThunk(
  "transation/selectOne",
  async (
    { paymentReference },
    { extra, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const result = await axios.get(
        url + `/filter?payment_reference=${paymentReference}`
      );
      const transaction = result.data.transaction;

      if (!transaction) {
        return rejectWithValue({
          message: `Aucune transaction n'est retrouvée pour la référence ${paymentReference}`,
        });
      }
      return fulfillWithValue(transaction);
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        message: error.response.data.message,
        code: error.response.status,
      });
    }
  }
);
