import React, { useState, useEffect, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import LoadingPage from "../../Utils/Style/LoadingPage";
import { useDispatch, useSelector } from "react-redux";
import { LoadingContext } from "../../Hooks";
import { transactionSelectAll } from "../Thunks/TransationThunk";
import { ErrorFlashBag } from "../../Utils/Style/FlashBags";
import { TransactionsTable } from "../../Components/Tables/TransactionsTable";

function Transaction(props) {
  const transactions = useSelector((state) => state.transactions.list);
  const status = useSelector((state) => state.transactions.status);
  const error = useSelector((state) => state.transactions.error);
  const dispatch = useDispatch();

  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const pageTitle = "Liste des transactions enregistrées";

  useEffect(() => {
    dispatch(transactionSelectAll());
  }, []);

  return (
    <div className="container-fluid px-4">
      <h1 className="mt-4">{pageTitle}</h1>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item">
          <Link rel="stylesheet" to="/">
            TABLEAU DE BORD
          </Link>
        </li>
        <li className="breadcrumb-item active">{pageTitle}</li>
      </ol>

      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          {pageTitle}
        </div>
        <div className="card-body overflow-auto">
          {status === "loading" && <LoadingPage />}
          {status === "failed" &&
            (error?.code === 401 ? (
              <Navigate to="/login" />
            ) : (
              <ErrorFlashBag message={error.message} />
            ))}
          {status === "succeeded" && (
            <TransactionsTable transactions={transactions} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Transaction;
